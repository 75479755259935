import { Flex, Grid, Heading } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import BlogPreview from '../blog/blog-preview';
import LinkButton from '../buttons/link-button';

function BlogPostPreviews() {
  const data = useStaticQuery(graphql`
    query {
      blogPosts: allContentfulBlogPost(limit: 3) {
        edges {
          node {
            id
            datePublished(formatString: "MMMM D, yyyy")
            image {
              title
              gatsbyImage(width: 400)
            }
            title
            tags
            articleBody {
              raw
            }
            excerpt {
              excerpt
            }
            postAuthor {
              fullName
            }
          }
        }
      }
    }
  `);
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="start"
      color="gray.800"
      w="100%"
    >
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Heading size="lg">From the Blog</Heading>
        <LinkButton text="See more posts" link="about/blog" />
      </Flex>
      <Grid
        gridTemplateColumns={{
          base: '1fr',

          lg: 'repeat(3, 1fr)',
        }}
        gap={8}
        w="100%"
        my={12}
      >
        {data.blogPosts.edges.map((post, index) => {
          const slug = post.node.title
            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
            .replace(/\s/gi, '-')
            .toLowerCase();

          return (
            <BlogPreview
              date={post.node.datePublished}
              key={post.node.title}
              title={post.node.title}
              excerpt={post.node.excerpt.excerpt}
              imageUrl={getImage(post.node.image.gatsbyImage)}
              author={post.node.postAuthor?.fullName || 'Parentshop Staff'}
              url={slug}
              tags={post.node.tags}
              alt={post.node.image.title}
            />
          );
        })}
      </Grid>
    </Flex>
  );
}

export default BlogPostPreviews;
