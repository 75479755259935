import { Box, Flex, Heading, Text, chakra } from '@chakra-ui/react';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import React from 'react';

function BlogPreview({
  title,
  author,
  excerpt,
  tags,
  imageUrl,
  url,
  date,
  alt,
  external = true,
}) {
  return (
    <Link to={external ? `/blog/${url}` : `/${url}`}>
      <Flex
        flexDir="column"
        justifyContent="start"
        alignItems="center"
        w="100%"
        h="100%"
        borderRadius="2xl"
        // border="1px solid"
        borderColor="gray.200"
        boxShadow="md"
        bgColor="white"
        overflow="hidden"
        cursor="pointer"
        transition="all .1s ease-out"
        _hover={{ boxShadow: 'xl', transform: 'translateY(-5px)' }}
      >
        <Box w="100%" minH="15rem" h="15rem" overflow="hidden">
          <Img
            image={imageUrl}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover', objectPosition: 'center' }}
            alt={alt}
          />
        </Box>
        <Flex flexDir="column" p={6} w="100%" h="100%">
          <Flex flexDir="column">
            <Flex w="100%" wrap="wrap">
              {tags.map((tag, i) => {
                if (i > 2) return;
                return (
                  <Text
                    mb={3}
                    px={3}
                    py={0.75}
                    fontSize={12}
                    bgColor="gray.100"
                    color="gray.600"
                    textTransform="capitalize"
                    fontWeight="semibold"
                    borderRadius="xl"
                    mr={i === tags.length - 1 ? 0 : 4}
                  >
                    {tag}
                  </Text>
                );
              })}
            </Flex>
            <Heading
              size="md"
              fontWeight="600"
              lineHeight="1.4"
              color="gray.800"
              pt={1}
              pb={2}
              textTransform="capitalize"
            >
              {title}
            </Heading>
            <Text fontSize="sm" color="gray.500" mb={2}>
              By{' '}
              <chakra.span color="gray.700" fontWeight="semibold" mr={2}>
                {author}
              </chakra.span>
              •
              <chakra.span ml={2} fontSize="sm" color="gray.500">
                {date}
              </chakra.span>
            </Text>
            <Text color="gray.700">{excerpt}</Text>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
}

export default BlogPreview;
