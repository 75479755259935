import {
  Flex,
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import CoursePreviewCard from '../courses/course-preview-card';

function UpcomingCourses({ courses, parentsOnly = false, category = false }) {
  const primaryCourses = courses.filter((course) =>
    course?.node?.subcategory?.includes('primary teachers & leaders')
  );

  const secondaryCourses = courses.filter((course) =>
    course?.node?.subcategory?.includes('secondary teachers & leaders')
  );

  const earlyYearsCourses = courses.filter((course) =>
    course?.node?.subcategory?.includes('early years educators')
  );

  const childFamSpecialistsCourses = courses.filter((course) =>
    course?.node?.subcategory?.includes('child & family specialists')
  );

  const parentCourses = courses.filter((course) =>
    course?.node?.subcategory?.includes('parents')
  );

  function getSubcategory(node) {
    let subcategory = node?.subcategory || 'professionals';
    if (node?.subcategory?.length > 1) {
      if (
        node?.subcategory?.includes('primary teachers & leaders') &&
        node?.subcategory?.includes('secondary teachers & leaders')
      ) {
        subcategory = 'teachers & leaders';
      } else {
        subcategory = 'professionals';
      }
    }
    return subcategory;
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="start"
      alignItems="center"
      mt={16}
      mb={24}
      color="gray.800"
      w="100%"
    >
      <Flex justifyContent="flex-start" alignItems="flex-end" w="100%">
        <Heading size="lg" textAlign={{ base: 'center', md: 'left' }}>
          Our courses
        </Heading>
      </Flex>
      <Tabs colorScheme="secondary" w="100%">
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          flexDir={{ base: 'column', md: 'row' }}
        >
          <TabList
            my={{ base: 4, md: 8 }}
            w="100%"
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
          >
            {!parentsOnly && (
              <>
                <Tab mr={4}>All courses</Tab>
                {!parentsOnly && !category && (
                  <>
                    <Tab mx={4} my={{ base: 5, md: 0 }}>
                      Primary Teachers & Leaders
                    </Tab>
                    <Tab mx={4}>Secondary Teachers & Leaders</Tab>
                    <Tab mx={4}>Early Years Educators</Tab>
                    <Tab mx={4}>Child & Family Specialists</Tab>
                    {parentCourses.length > 0 && <Tab mx={4}>Parents</Tab>}
                  </>
                )}
              </>
            )}
          </TabList>
          {/* <LinkButton text="View all courses" link="courses" /> */}
        </Flex>
        <TabPanels w="100%">
          {/* All courses */}
          <TabPanel w="100%" maxW="1280px" p={0}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
                '2xl': 'repeat(4, 1fr)',
              }}
              gap={6}
            >
              {courses.map(({ node }) => {
                const subcategory = getSubcategory(node);
                const image = getImage(node.courseImage.gatsbyImage)
                return (
                  <GridItem>
                    <CoursePreviewCard
                      image={image}
                      alt={node.courseImage.title}
                      title={node.courseName}
                      body={node.shortDescription}
                      category={subcategory}
                      key={node.slug}
                      slug={node.slug}
                      forParents={node.forParents}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </TabPanel>
          {/* Primary School Teachers & Leaders */}
          <TabPanel w="100%" maxW="1280px" p={0}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
                '2xl': 'repeat(4, 1fr)',
              }}
              gap={6}
            >
              {primaryCourses.map(({ node }) => {
                const subcategory = getSubcategory(node);
                return (
                  <GridItem>
                    <CoursePreviewCard
                      image={getImage(node.courseImage.gatsbyImage)}
                      title={node.courseName}
                      body={node.shortDescription}
                      category={subcategory}
                      key={node.slug}
                      slug={node.slug}
                      forParents={node.forParents}
                      alt={node.shortDescription}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </TabPanel>
          {/* Secondary School Teachers & Leaders */}
          <TabPanel w="100%" maxW="1280px" p={0}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
                '2xl': 'repeat(4, 1fr)',
              }}
              gap={6}
            >
              {secondaryCourses.map(({ node }) => {
                const subcategory = getSubcategory(node);
                return (
                  <GridItem>
                    <CoursePreviewCard
                      image={getImage(node.courseImage.gatsbyImage)}
                      title={node.courseName}
                      body={node.shortDescription}
                      category={subcategory}
                      key={node.slug}
                      slug={node.slug}
                      forParents={node.forParents}
                      alt={node.shortDescription}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </TabPanel>
          {/* Early Years Educators */}
          <TabPanel w="100%" maxW="1280px" p={0}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
                '2xl': 'repeat(4, 1fr)',
              }}
              gap={6}
            >
              {earlyYearsCourses.map(({ node }) => {
                const subcategory = getSubcategory(node);
                return (
                  <GridItem>
                    <CoursePreviewCard
                      image={getImage(node.courseImage.gatsbyImage)}
                      title={node.courseName}
                      body={node.shortDescription}
                      category={subcategory}
                      key={node.slug}
                      slug={node.slug}
                      forParents={node.forParents}
                      alt={node.shortDescription}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </TabPanel>
          {/* Child & Family Specialists */}
          <TabPanel w="100%" maxW="1280px" p={0}>
            <Grid
              templateColumns={{
                base: '1fr',
                md: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
                '2xl': 'repeat(4, 1fr)',
              }}
              gap={6}
            >
              {childFamSpecialistsCourses.map(({ node }) => {
                const subcategory = getSubcategory(node);
                return (
                  <GridItem>
                    <CoursePreviewCard
                      image={getImage(node.courseImage.gatsbyImage)}
                      title={node.courseName}
                      body={node.shortDescription}
                      category={subcategory}
                      key={node.slug}
                      slug={node.slug}
                      forParents={node.forParents}
                      alt={node.shortDescription}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </TabPanel>
          {/* Parents */}
          {parentCourses.length && (
            <TabPanel w="100%" maxW="1280px" p={0}>
              <Grid
                templateColumns={{
                  base: '1fr',
                  md: 'repeat(2, 1fr)',
                  xl: 'repeat(3, 1fr)',
                  '2xl': 'repeat(4, 1fr)',
                }}
                gap={6}
              >
                {parentCourses.map(({ node }) => {
                  const subcategory = getSubcategory(node);
                  return (
                    <GridItem>
                      <CoursePreviewCard
                        image={getImage(node.courseImage.gatsbyImage)}
                        title={node.courseName}
                        body={node.shortDescription}
                        category={subcategory}
                        key={node.slug}
                        slug={node.slug}
                        forParents={node.forParents}
                        alt={node.shortDescription}
                      />
                    </GridItem>
                  );
                })}
              </Grid>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default UpcomingCourses;
